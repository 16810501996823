import React, { Component } from 'react';

class ProgressBar extends Component {

  render() {
    return(
      <div className="progress">
        <span className="percentage">{this.props.percentage}</span>
        <span style={{ width: this.props.percentage}}></span>
      </div>
    )
  }
}

class FrontEnd extends Component {

  render() {
    return (
      <div className='lang front-end'>
        <h3>Front End</h3>
        <ul className='skills-list'>
          <li>jQuery</li>
          <li>Javascript</li>
          <li>HTML</li>
          <li>CSS</li>
          <li>Bootstrap</li>
          <li>SASS</li>
        </ul>
      </div>
    )
  }
}


class BackEnd extends Component {

  render() {
    return (
      <div className='lang back-end'>
        <h3>Back End</h3>
        <ul className='skills-list'>
          <li>React</li>
          <li>Express</li>
          <li>NodeJS</li>
          <li>Django</li>
        </ul>
      </div>
    )
  }
}

class DataBase extends Component {

  render() {
    return(
      <div className= "lang database" >
        <h3>Databases</h3>
        <ul className="skills-list">
          <li>MongoDb</li>
          <li>PostgresQL</li>
          <li>MySQL</li>
        </ul>
      </div>
    )
  }
}

export default class Skills extends Component {

  render() {
    return(
      <section id="skills" className="skills">
        <h3 className="title">Skills</h3>
        <div className="skills-container">
          <FrontEnd />
          <BackEnd />
          <DataBase />
        </div>
      </section>
    )
  }

}