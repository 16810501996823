import React from 'react';

import './Home.scss';
import NavBar from './NavBar';
import Profile from './Profile';
import Skills from './Skills'
import ProjectDetail from './Project-detail';
import WorkExperience from './WorkExperience';
import Education from  './Education';

function Home() {
  return (
    <div className="container">
    <NavBar/>
    
      <main className="inner-container">
      <Profile/>
      <ProjectDetail/>
      <Skills />
      <WorkExperience/>
      <Education/>
      </main>
    </div>
  );
}

export default Home;
