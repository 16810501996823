import React, { Component } from 'react';
import Project from './Project';

import WallNoPoly from './build/project3.png';
import ProjectRecapture from './build/project2.png'
import Pai from './build/project1.png'

class ProjectDetail extends Component {
  render() {
    return(
      <section className="projects" id="projects">
      <h3 className="title">Projects</h3>
      <div className='all-project-container'>
        <Project 
          projectName="Project-Recapture" 
          projectImage={ ProjectRecapture }
          description="Front-end developer work with Project Recapture Inc"
          myWorks={['Created custom dropdown, slide functions using Javascript and CSS', 'Designed and developed various pages']}
          projectSkills={['HTML5', 'CSS', 'jQuery', 'Javascript', 'Github' ]}
          githubLink="https://github.com/PeterHjHan/benttree"
        />
        <Project 
          projectName="Mamihlapinatapai" 
          projectImage= { Pai }
          description="A React Native app that matches with random users and indicates the distance between two users through gps"
          myWorks={['Mainily responsible for geolocation using websockets and database calls', 'Supporting for  Backend', 'Developed front end Pulse Animation']}
          projectSkills={['React Native', 'WebSocket', 'Express', 'PostgreSQL', 'Knex', 'Github',]}
          githubLink="https://github.com/PeterHjHan/Pai"
        />
        <Project 
          projectName="WallNoPoly" 
          projectImage={ WallNoPoly }
          description="A collaborative resource wall (Pinterest clone) with a Monopoly property card theme"
          myWorks={['Created fontend CSS', 'Developed custom functions for data collection', 'Developed Ajax functions']}
          projectSkills={['jQuery', 'Ajax', 'HTML5', 'Bootstrap', 'CSS', 'Javascript','Github']}
          githubLink="https://github.com/PeterHjHan/resource-wall"
        />
      </div>
      </section>
    )
  }
}

export default ProjectDetail;