import React, { Component } from 'react';
import { FiGithub} from 'react-icons/fi'
import { MdChevronRight, MdChevronLeft } from 'react-icons/md'; 


function ProjectSkills(props) {
  const skills = props.projectSkills;
  const listItems = skills.map((skill, index) =>
  <li key={index}>{skill}</li> 
  )

  return (
    <ul className="project-skills">
      {/* <p className='project-skills-title'>skills</p> */}
      {listItems}
    </ul>
  )
}

function MyWorks(props) {
  const works = props.myWorks
  const listWorks = works.map((work,index) =>
  <li key={index}>{work}</li>
  )

  return (
    <ul className='project-works'>
      {listWorks}
    </ul>
  )
}

export default class Project extends Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      className : 'closed',
      imageClassName : '',
      infoPanel: '',
    }

    this.openDetails = this.openDetails.bind(this);
  }
  openDetails() {
    this.state.open ? 
      this.setState(
        { open: false, className: 'closed', imageClassName: '', infoPanel: '' })
      : this.setState(
        {open: true, className: 'open', imageClassName: 'image-hide', infoPanel: 'info-panel-open'});
  }
  render() {

    return(
      <article id="projects" className="project-container">
        <div className={"project-image " + this.state.imageClassName}>
         <img src={this.props.projectImage} alt={this.props.projectName}></img>
        </div>
          <div className="info" onClick={this.openDetails}>  
            <p>Info</p>
            {
              this.state.open ? 
              (
              <button className="arrow right" onClick={this.openDetails}><MdChevronRight size="2em"/></button>) :
              <button className="arrow left" onClick={this.openDetails}><MdChevronLeft size="2em"/></button>
            }
          </div>
          <div className={"project-details " + this.state.className}>
            <h3>{this.props.projectName}</h3>
            <p>{this.props.description}</p>
            <MyWorks myWorks={this.props.myWorks}/>
            <ProjectSkills projectSkills={this.props.projectSkills}/>
            <a target="_blank" rel="noopener noreferrer" href={this.props.githubLink}><FiGithub className="social-btn"/></a>
            {/* <button> <a target="_blank" href={this.props.githubLink}/>Github</button> */}
          </div>  
      </article>  
    )
  }

}