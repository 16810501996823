import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

// import './DarkTheme.scss';
import Home from './components/Home'
import ProjectDetail from './components/Project-detail';

function App() {
  return (
    <Router>
    <Switch>
        <Route path='/' component={Home} />
        <Route path='/project/1' component={ProjectDetail} />
     </Switch>
    </Router>
  );
}

export default App;
