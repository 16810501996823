import React, { Component } from 'react';

class LighthouseLabs extends Component {
  render() {
    return(
      <article className="record">
        <h3>Full-stack Web Development</h3>
        <p className="details">Lighthouse Labs</p>
        <p className="date">September 2018 - November 2018</p>
        <ul>
          <li>Intense ten week bootcamp using Javascript, HTML5, NodeJS, React</li>
        </ul>
      </article>
    )
  }
}

class UBC extends Component {
  render() {
    return (
      <article className="record">
        <h3>Bachelor of Science: Nutritional Health</h3>
        <p className="details">University of British Columbia</p>
        <p className="date">Graduated: May 2014</p>
      </article>
    )
  }
}

export default class Education extends Component {

  render() {
    return(
      <section id="education" className="education">
        <h3 className="title">Education</h3>
        <LighthouseLabs/>
        <UBC/>
      </section>
    )
  }
}