import React, { Component } from 'react';

class ProjectRecapture extends Component {
  render() {
    return (
      <article className="record">
      
      <h3>Front End Developer</h3>
      <p className="details">Project Recapture </p>
      <p className="date">February - May 2019</p>
      <ul>
        <li>Completed implementing design details using HTML and CSS from a given Adobe XD file.</li>
        <li>Integrated custom functions for jQuery DataTables to meet the functional needs.</li>
        <li>Designed and implemented HTML, CSS, jQuery and Javascript codes for user interface and user experience.</li>
        <li>Weekly meetings and constructive feedback and criticism with senior development in regards to coding best practices, time efficiency.</li>
      </ul>
    </article>
    )
  }
}

class TELUS extends Component {
  render() {
    return (
    <article className="record">
      <h3>TELUS Mobility Dealer & Client Support Representative</h3>
      <p className="details">TELUS Corporation</p>
      <p className="date">April 2015 - September 2018</p>
      <ul>
         <li>Created and maintained internal documentation with HTML5 and CSS in regards to specialized procedures necessary to ensure optimal customer experience</li>
        <li>Assist Corporate stores, Independent dealers and Retailers with higher escalated issue and handled issues in a timely manner through calls, emails and chats.</li>
        <li>Level 3 support for co-workers in regards to troubleshooting, escalated situations, and internal systems support.</li>
        <li>Prepared and conducted training sessions for other team-members in regards to new protocols and best practices.</li>
      </ul>
    </article>
    )
  }
}

export default class WorkExperience extends Component {

  render() {
    return(
      <section id="experience" className="work-experience">
        <h3 className="title">Professional Work Experience</h3>
        <ProjectRecapture/>
        {/* <TELUS/> */}
      </section>
    )
  }
}