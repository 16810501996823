import React, { Component } from 'react';
import { FaMobileAlt } from 'react-icons/fa'
import { GoMail, GoHome } from 'react-icons/go'
import { FiLinkedin, FiGithub} from 'react-icons/fi'

import Resume from './build/Peter_Han_Resume.pdf';

export default class Profile extends Component {

  constructor(props){
    super(props);
    this.getResume = this.getResume.bind(this);
  }

  getResume() {
    return window.open(Resume, "_blank");
  }
  
  render() {
    return(
      <section id="profile" className="profile">
        <header className="title">
          <h2>Peter Han</h2>
          <h4>Junior Full Stack Web Devleloper</h4>
        </header>

        <p>As a Junior Full-stack Web Developer, I am always eager to learn new technologies in addition to cultivating my existing techniques. I am a quick learner, adaptable, self-efficient and motivated to work in a team environment. I chose to become a web developer due to the infinite possibilities of contributing to our communities through this technology.</p>

        <button className="btn resume" onClick={this.getResume}>Resume</button>
          <ul className="social">

            <li><a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/peterhjhan"><FiLinkedin className="social-btn"/></a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://github.com/PeterHjHan"><FiGithub className="social-btn"/></a></li>
            <li><a href="mailto:peterhjhan@gmail.com"><GoMail className='social-btn'/></a></li>
          </ul>
        

      </section>
    )
  }
}
