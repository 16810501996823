import React, { Component } from 'react';
import { Link, animateScroll as Scroll} from 'react-scroll';
import { thisExpression } from '@babel/types';

export default class NavBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      darkTheme: false,
      scrollPosition: 300,
      showNav : true,
    }
    this.toggleNav = this.toggleNav.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.changeCss = this.changeCss.bind(this);
    this.showNavOnScroll = this.showNavOnScroll.bind(this);
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('scroll', this.showNavOnScroll);
  }

  componentWillUnmount() {
    this.updateDimensions();
    window.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener('scroll', this.showNavOnScroll);
  }

  updateDimensions() {

    //Watches for current window dimension when the screen is loaded to make sure proper navigation is displayed
    if(window.innerWidth > 1280 ) {
      let hidden = document.getElementsByClassName('image-hide');
      let projectToggle = document.getElementsByClassName('closed');
   
      this.setState({ open:true });
      if(hidden.length !== 0) {
        hidden[0].classList.remove('image-hide');
      }

      while(projectToggle.length !== 0) {
        projectToggle[0].classList.remove('closed');
      }

    } else {
      this.setState({ open:false });
    }
  }

  showNavOnScroll() {
    const { scrollPosition } = this.state; 
    const currentPosition = window.pageYOffset;
    const visible = scrollPosition > currentPosition

    if(currentPosition > 300) {
      this.setState({
        scrollPosition : currentPosition,
        showNav: visible,
      })
    }
  }

  toggleNav() {
    //Expand nav when in mobile screen size
    if(window.innerWidth < 1280) {
      this.state.open ? this.setState({open: false}) : this.setState({open: true});
    }
  }

  changeCss(e) {
    //implements darkTheme when Dark Theme Button is clicked
    let container = document.getElementsByClassName('container');
    let darkTheme = this.state.darkTheme;
    let darkThemeClassName = 'darkTheme';

    if( darkTheme ) {
      this.setState({
        darkTheme: !darkTheme
      });
      container[0].classList.remove(darkThemeClassName);
    } else {
      this.setState({
        darkTheme : !darkTheme,
      });

      container[0].classList.add(darkThemeClassName);
    }

    if(this.state.open) {
      this.toggleNav();
    }
  }

  render() {
    const sectionNames =
      [
        {name: 'profile', label: 'About Me'}, 
        {name: 'projects', label: 'Project'}, 
        {name: 'skills', label: 'Skills'}, 
        {name: 'experience', label: 'Experience'}, 
        {name: 'education', label: 'Education'}
      ];

    const sectionLink = sectionNames.map((section) => 

        <li className='testing' onClick={this.toggleNav} key={section.name}>
          <Link 
            activeClass='active'
            to={section.name}
            spy={true}
            smooth={true}
            duration={500}
            >
              {section.label}
            </Link>
          <div className="menu-underline"></div>
        </li>
    )


    return(
      <nav className={this.state.showNav ? 'navbar-container' : 'navbar-container nav-hidden'} id="main" >
        <button className="btn dark-theme-btn" onClick={this.changeCss}>
          {this.state.darkTheme ? "Light Mode" : "Dark Mode"}
        </button>

        <span></span>
        <div>
            <div className="navbar-icon" onClick={this.toggleNav} alt="">
              <hr className="line"></hr>
              <hr className="line"></hr>
              <hr className="line"></hr>
            </div>
        { this.state.open ? (
          <ul className="menu" onMouseLeave={this.toggleNav}>
            {sectionLink}
          </ul>
        ) :
        ( 
        null 
        )
        }
        </div>
      </nav>  
      
      
    )
  }

}